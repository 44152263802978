<template>
  <SectionTitle
    icon="icon-about"
    h2="Sajnáljuk, a keresett oldal nem található..."
    h2small="...de az alábbi linkek biztosan működnek"
    createdate=""
    tag1=""
    tag1url=""
    tag2=""
    tag2url=""
    tag3=""
    tag3url=""
  />

  <section class="section section-body">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 offset-lg-2 text-center">
          <div class="d-grid m-auto">
            <router-link
              @click="hidden = !hidden"
              class="btn btn-rainbow pt-4 pb-4"
              to="/szolgaltatasaink"
            >
              <span>Ismerje meg szolgáltatásainkat!</span>
            </router-link>
          </div>
        </div>
        <div class="col-12 col-lg-4 text-center mt-3 mt-lg-0">
          <div class="d-grid m-auto">
            <router-link
              @click="hidden = !hidden"
              class="btn btn-rainbow pt-4 pb-4"
              to="/termekeink"
            >
              <span>Ismerje meg termékeinket!</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-lg-4 offset-lg-2 text-center">
          <div class="d-grid m-auto">
            <router-link
              @click="hidden = !hidden"
              class="btn btn-rainbow pt-4 pb-4"
              to="/"
            >
              <span>Főoldal</span>
            </router-link>
          </div>
        </div>
        <div class="col-12 col-lg-4 text-center mt-3 mt-lg-0">
          <div class="d-grid m-auto">
            <router-link
              @click="hidden = !hidden"
              class="btn btn-rainbow pt-4 pb-4"
              to="/rolunk"
            >
              <span>Rólunk</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";

export default {
  components: {
    SectionTitle,
  },
};
</script>
