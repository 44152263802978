<template>
  <div class="section section-title">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <img
            :src="require('@/assets/img/all/' + icon + '.svg')"
            alt=""
            class="img-fluid h2-icon"
          />
          <br />
          <h2 v-html="h2"></h2>
          <br />
          <small class="h2-small" v-if="h2small != ''" v-html="h2small"></small>
        </div>
      </div>
      <div v-if="createdate != ''" class="row">
        <div class="col-12 col-lg-6 text-center text-lg-end">
          <span v-html="createdate"></span>
        </div>
        <div class="col-12 col-lg-6 text-center text-lg-start">
          <ul class="list-tags list-unstyled list-inline">
            <li class="list-inline-item">
              <router-link to="/blog/tanfolyam" v-html="tag1"></router-link>
            </li>
            <li class="list-inline-item">
              <router-link
                to="/szolgaltatasaink/frontend-development"
                v-html="tag2"
              >
              </router-link>
            </li>
            <li class="list-inline-item">
              <router-link
                to="/szolgaltatasaink/frontend-development/vuejs"
                v-html="tag3"
              ></router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    h2: String,
    h2small: String,
    createdate: String,
    tag1: String,
    tag1url: String,
    tag2: String,
    tag2url: String,
    tag3: String,
    tag3url: String,
  },
};
</script>
